'use strict';

const api = require('../../helpers/api');
const helper = require('../../helpers/helper');

const loaderInstance = require('../../components/loader');
const $loaderContainer = document.querySelector('.paypalLoader');
const $usedPaymentMethodName = document.getElementById('usedPaymentMethod');

const disableFunds = [
    'sepa',
    'bancontact',
    'eps',
    'giropay',
    'ideal',
    'mybank',
    'p24',
    'sofort',
    'venmo',
    'blik'
];

let that = null;

/**
 * Returns value whether LPM was used or not
 * @param {string} usedPaymentMethod - The payment method name
 * @returns {boolean} value whether LPM was used
 */
const isLpmUsed = usedPaymentMethod => {
    return disableFunds.indexOf(usedPaymentMethod) !== -1;
};

/**
 * Initiates a PayPalButtonBase model
 * @param {string} payPalBtnSelector A container class where PayPal button will be initiated
 */
function PayPalBaseModel(payPalBtnSelector) {
    const AlertHandlerModel = require('../alertHandler');

    this.payPalBtnSelector = payPalBtnSelector;
    this.$payPalBtnContainer = document.querySelector(payPalBtnSelector);
    this.isPayNowFlowEnabled = window.paypalPreferences.isPayNowFlowEnabled;
    this.usedPaymentMethodName = null;
    this.loader = loaderInstance($loaderContainer);
    this.alertHandler = new AlertHandlerModel();

    this.isBtnEligible = null;
    this.fundingSource = null;
}

/**
 * Process the Pay Now flow
 */
PayPalBaseModel.prototype.processPayNowFlow = function() {
    const SessionStorageModel = require('../sessionStorage');
    const sessionStorageInstance = new SessionStorageModel();

    api.finishPayNowPayment(this.usedPaymentMethodName);

    sessionStorageInstance.clear();
};

/**
 * Process the generic (not Ba) Paypal button flow
 */
PayPalBaseModel.prototype.processGenericBtnFlow = function() {
    const $selectedPaypalAccount = document.getElementById('sessionPaypalAccount');
    const $selectedVenmoAccount = document.getElementById('sessionVenmoAccount');

    if (that.usedPaymentMethodName !== window.paypalConstants.PAYMENT_METHOD_ID_VENMO && $selectedPaypalAccount) {
        if ($selectedPaypalAccount.value !== '') {
            $selectedPaypalAccount.value = '';
            $selectedPaypalAccount.innerText = '';
        }

        $selectedPaypalAccount.selected = true;
        $selectedPaypalAccount.style.display = 'block';
    } else if ($selectedVenmoAccount) {
        $selectedVenmoAccount.selected = true;
    }

    document.querySelector('button.submit-payment').click();
    this.loader.hide();
};

/**
 * Saves a used payment method to hidden input
 * @param {Object} data - object with data
 */
PayPalBaseModel.prototype.onClick = function(data) {
    if (data.fundingSource === window.paypal.FUNDING.CARD) {
        that.usedPaymentMethodName = 'PayPal Debit/Credit Card';
    } else if (data.fundingSource === window.paypal.FUNDING.VENMO) {
        that.usedPaymentMethodName = window.paypalConstants.PAYMENT_METHOD_ID_VENMO;
    } else if (data.fundingSource === window.paypal.FUNDING.PAYPAL) {
        that.usedPaymentMethodName = window.paypalConstants.PAYMENT_METHOD_ID_PAYPAL;
    } else {
        that.usedPaymentMethodName = data.fundingSource;
    }

    if ($usedPaymentMethodName) {
        $usedPaymentMethodName.value = that.usedPaymentMethodName;
    }
};

/**
 * Gets purchase units object, creates order and returns object with data
 * @returns {Object} With purchase units, payer and application context
 */
PayPalBaseModel.prototype.createOrder = function() {
    return api.getPaypalOrderId();
};

/**
 * Clears session account if it exists and irrelevant errors,
 * and clicks submit payment button OR
 * Finish PayNow flow
 * Is used with adjacent instances (cart, pdp)
 */
PayPalBaseModel.prototype.onApprove = function() {
    that.loader.show();

    // Pay Now flow. Using whether when an appropriate site preference is enabled or in case of LPM
    if (isLpmUsed(that.usedPaymentMethodName) || that.isPayNowFlowEnabled) {
        that.processPayNowFlow();
    } else {
        that.processGenericBtnFlow();
    }
};

/**
 * Hides loader on paypal widget closing without errors
 * Is used with adjacent instances (cart, pdp)
 */
PayPalBaseModel.prototype.onCancel = function() {
    that.loader.hide();
};

/**
 * Shows errors if paypal widget was closed with errors
 * Is used with adjacent instances (cart, pdp)
 */
PayPalBaseModel.prototype.onError = function() {
    that.loader.hide();
    that.alertHandler.showError(window.paypalConstants.FLASH_MESSAGE_ERROR_INTERNAL_SERVER);
};

/**
 * Renders a button mark(tab)
 */
PayPalBaseModel.prototype.renderButtonMarks = function() {
    window.paypal.Marks({
        fundingSource: this.fundingSource
    }).render(`.js-${this.fundingSource}-mark`);
};

/**
 * Renders the payment fields for LPM
 */
PayPalBaseModel.prototype.renderPaymentFields = function() {
    window.paypal.PaymentFields({
        fundingSource: this.fundingSource
    }).render(this.payPalBtnSelector);
};

/**
 * Shows a tab by funding source
 * @param {string} fs The funding source
 */
PayPalBaseModel.prototype.showButtonTab = function(fs) {
    document.querySelector(`.js-nav-item-${fs}`).classList.remove('d-none');
};

/**
 * Returns a generic PayPal's button parameter object
 * @returns {Object} A generic button parameters object
 */
PayPalBaseModel.prototype.createBtnGeneralOption = function() {
    that = this;

    return {
        onClick: this.onClick,
        createOrder: this.createOrder.bind(this, this.payPalBtnSelector),
        onApprove: this.onApprove,
        onCancel: this.onCancel,
        onError: this.onError,
        style: helper.getPaypalButtonStyle(this.$payPalBtnContainer)
    };
};

/**
 * Set color white or black for button (Debit/Credit Card and PayPal Credit)
 * @param {Object} property - PayPal general options (option style)
 * @param {string} fundingSource - Funding Source
 * @returns {void}
 */
function setPayPalButtonColor(property, fundingSource) {
    // Only white and black colors are available for both Debit/Credit Card and PayPal Credit btn
    if ([window.paypal.FUNDING.CARD, window.paypal.FUNDING.CREDIT].includes(fundingSource)
    && !['white', 'black'].some(el => el === property.style.color)) {
        // Sets the default color in case if color is not available for both Debit/Credit Card or PayPal Credit btn
        if (fundingSource === window.paypal.FUNDING.CREDIT) {
            property.style.color = 'darkblue';
        } else {
            property.style.color = 'black';
        }
    }
}

/**
 * Initiates buttons for the PayPal tab
 * @param {Object} btnProperty The button property
 */
PayPalBaseModel.prototype.initBtnsForPayPalTab = function(btnProperty) {
    const eligibleFundingSources = window.paypal.getFundingSources().filter(fs => window.paypal.Buttons({
        fundingSource: fs
    }).isEligible());

    const PAYPAL_FS = window.paypal.FUNDING.PAYPAL;

    let isTabShown = false;

    if (eligibleFundingSources.length) {
        this.renderButtonMarks.call({
            fundingSource: PAYPAL_FS
        });

        eligibleFundingSources.forEach(fs => {
            const isEnabled = !disableFunds.includes(fs);

            if (isEnabled) {
                setPayPalButtonColor(btnProperty, fs);

                window.paypal.Buttons(Object.assign(btnProperty, {
                    fundingSource: fs
                })).render(this.payPalBtnSelector);

                if (!isTabShown) {
                    // Shows the PayPal tab
                    this.showButtonTab(PAYPAL_FS);
                    isTabShown = true;
                }
            }
        });
    }
};

/**
 * Initiates a button by the provided funding source
 * @param {string} fs The function source(venmo, mybank...)
 * @param {Object} btnProperty The button property
 */
PayPalBaseModel.prototype.initBtnByFundingSource = function(fs, btnProperty) {
    const btnInitResult = window.paypal.Buttons(Object.assign(btnProperty, {
        fundingSource: fs
    }));

    this.isBtnEligible = btnInitResult.isEligible();

    if (this.isBtnEligible) {
        this.renderButtonMarks();

        btnInitResult.render(this.payPalBtnSelector);

        this.showButtonTab(fs);
    }
};

/**
 * Initiates a Paypal button on billing checkout page
 * Is used with adjacent instances (cart, pdp, pvp)
 * @param {string} fundingSource The funding source of payment method
 */
PayPalBaseModel.prototype.initPayPalButton = function(fundingSource) {
    this.loader.show();
    this.fundingSource = fundingSource || window.paypal.FUNDING.PAYPAL;

    const btnProperty = this.createBtnGeneralOption();

    if (fundingSource) {
        this.initBtnByFundingSource(fundingSource, btnProperty);
    } else {
        this.initBtnsForPayPalTab(btnProperty);
    }

    this.loader.hide();
};

module.exports = PayPalBaseModel;
