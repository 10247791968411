/**
 * Applies PayPal button behavior
 */
function applyPaypalButtonBehavior() {
    const { initPaypalButtonBehaviorOnPvp, initPayPalBtnBehaviorOnSet } = require('./helpers/helper');

    const paypalButton = document.querySelector('.paypal-pvp-button-global');
    const isProductSet = Boolean(document.querySelector('.set-items'));

    if (isProductSet) {
        initPayPalBtnBehaviorOnSet();
    } else if (paypalButton) {
        initPaypalButtonBehaviorOnPvp(paypalButton);
    }
}

/**
 * Inits PayPal static image functionality on PVP
 */
function initStaticImageFunctionality() {
    const { addProductToCart } = require('./helpers/helper');
    const { returnFromCart, validateStaticImageAddress } = require('./helpers/api');
    const loaderInstance = require('./components/loader');

    const paypalStaticImageContainers = document.querySelectorAll('.paypal-static-image-container');

    let isAddressNeedChange;

    validateStaticImageAddress().then(isNeed => {
        isAddressNeedChange = isNeed;
    });

    paypalStaticImageContainers && paypalStaticImageContainers.forEach((container, index) => {
        const staticImage = container.querySelector('#paypal-static-image');
        const selector = `${container.className}${index}`;

        staticImage.classList.add(selector);

        staticImage.addEventListener('click', function() {
            const loaderContainer = this.closest('.pdp-paypal-button-container').querySelector('.paypalLoader');
            const loader = loaderInstance(loaderContainer);

            loader.show();

            const result = addProductToCart(`.${this.className}`);

            if (result.cart) {
                returnFromCart(isAddressNeedChange);
            } else {
                loader.hide();

                throw new Error(result.message || 'Error occurs while trying to add product to the cart');
            }
        });
    });

    applyPaypalButtonBehavior();
}

/**
 * Inits PayPal functionality on PVP
 */
function initPaypalFunctionality() {
    const PayPalProductModel = require('./models/buttons/payPalProduct');
    const PayPalProductBAModel = require('./models/billingAgreement/payPalProduct');

    const PP_BTN_SELECTOR = '.js-paypal-button-on-product-page';
    const isBAEnabled = window.paypalPreferences.billingAgreementEnabled;
    const paypalButtonContainers = document.querySelectorAll('.paypal-pvp-button, .paypal-pvp-button-global');

    paypalButtonContainers && paypalButtonContainers.forEach((container, index) => {
        const ppButton = container.querySelector(PP_BTN_SELECTOR);

        if (ppButton) {
            ppButton.classList.add(`${ppButton.classList[1]}-${index}-pvp`);

            const selector = `.${Array.from(ppButton.classList).join('.')}`;

            let payPalProductInstance;

            if (isBAEnabled) {
                payPalProductInstance = new PayPalProductBAModel(selector);
            } else {
                payPalProductInstance = new PayPalProductModel(selector);
            }

            payPalProductInstance.initPayPalButton();
        }
    });

    applyPaypalButtonBehavior();
}

/**
 * When the page loads, inject the PayPal SDK into the page and then call the initPaypalFunctionality
 * function.
 */
function injectPaypalSDK() {
    const head = document.getElementsByTagName('head').item(0);
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = window.paypalSDK.cartSdkUrl;
    script.onload = initPaypalFunctionality;
    script.setAttribute('data-partner-attribution-id', window.paypalPreferences.partnerAttributionId);
    head.appendChild(script);
}

$('body').on('quickview:ready', () => {
    const isPaypalStaticImage = Boolean(document.getElementById('paypal-static-image'));

    if (isPaypalStaticImage) {
        initStaticImageFunctionality();
    } else if (window.paypal) {
        initPaypalFunctionality();
    } else {
        injectPaypalSDK();
    }
});
