'use strict';

const PayPalBaseModel = require('./payPalBase');
const helper = require('../../helpers/helper');

/**
 * Initiates a PayPalButtonExpress model (pvp, pdp, cart, mini-cart)
 * @param {string} payPalBtnSelector A container class where PayPal button will be initiated
 */
function PayPalExpressModel(payPalBtnSelector) {
    PayPalBaseModel.call(this, payPalBtnSelector);
}

PayPalExpressModel.prototype = Object.create(PayPalBaseModel.prototype);

/**
 * Process the generic (not Ba) Paypal button flow (cart, pdp, mini-cart)
 */
PayPalExpressModel.prototype.processGenericBtnFlow = function() {
    const that = this;

    helper.returnFromCart.call(that, {})
        .catch((error) => {
            this.loader.hide();

            if (this.payPalBtnSelector === '.js-paypal-button-on-cart-page') {
                this.onError(error);
            } else if (!helper.handleValidationAddressResult(error)) {
                window.location.href = window.paypalUrls.cartPage;
            } else if (this.payPalBtnSelector.includes('.paypal-pdp-button')) {
                helper.removeAllProductsFromCart();
            }
        });
};

/**
 * Initiates a Paypal button on express checkout page (Cart, mini-cart, pdp)
 * Is used with adjacent instances (cart, pdp, pvp)
 */
PayPalExpressModel.prototype.initPayPalButton = function() {
    this.loader.show();

    const btnInitResult = window.paypal.Buttons(this.createBtnGeneralOption());

    if (btnInitResult.isEligible()) {
        btnInitResult.render(this.payPalBtnSelector);

        this.loader.hide();
    }
};

module.exports = PayPalExpressModel;
