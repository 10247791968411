'use strict';

const helper = require('../../helpers/helper');

const PayPalBaseBAModel = require('./payPalBase');

class PayPalProductBAModel extends PayPalBaseBAModel {
    constructor(selector) {
        super(selector);

        this.isCartFlow = true;
    }

    /**
     * Adds product to the cart and creates Billing Agreement
     * @returns {string} returns JSON response that includes a data token
     */
    createBillingAgreement() {
        helper.addProductToCart(this.selector);

        return super.createBillingAgreement();
    }

    /**
     * Makes post call to create a BA and calls the returnFromCart endpoint, triggers checkout (stage = place order)
     * @returns {Object} JSON response that includes the billing agreement token
     */
    onApprove() {
        const that = this;

        return super.onApprove()
            .then((result) => {
                return helper.returnFromCart.call(that, result);
            })
            .fail((error) => {
                that.loader.hide();

                const errorResponse = error.responseJSON;

                if (helper.handleValidationAddressResult(error) || errorResponse.errorName === 'shipping.address.invalid') {
                    helper.removeAllProductsFromCart();

                    that.payPalInstance.close();

                    if (errorResponse.message) {
                        that.alertHandler.showError(errorResponse.message);
                    }
                } else {
                    window.location.href = window.paypalUrls.cartPage;
                }
            });
    }

    /**
     * Hides loader on paypal widget closing without errors.
     * Removes product from basket on cancel
     */
    onCancel() {
        helper.removeAllProductsFromCart();

        super.onCancel();
    }

    /**
     * Shows errors if paypal widget was closed with errors.
     * Removes product from basket on error
     */
    onError() {
        helper.removeAllProductsFromCart();

        super.onError();
        window.location.href = window.paypalUrls.cartPage;
    }

    /**
     * Inits paypal Billing Agreement button on PDP/PVP pages
     */
    initPayPalButton() {
        const that = this;

        super.initPayPalButton();

        this.payPalInstance.render(this.selector)
            .then(() => {
                that.loader.hide();
            });
    }
}

module.exports = PayPalProductBAModel;
