'use strict';

const defaultStyle = {
    color: 'gold',
    shape: 'rect',
    layout: 'vertical',
    label: 'paypal',
    tagline: false
};

const HIDE_CLASS = 'none';
const PRODUCT_DETAIL_CLASS = '.product-detail';
const PDP_PRICE_VALUE_CLASS = '.price .sales .value';
const INVALID_CLASSNAME = 'is-invalid';

const base = require('base/product/base');

/**
 *  Gets paypal button styles
 * @param {Element} button - button element
 * @returns {Object} with button styles or if error appears with default styles
 */
function getPaypalButtonStyle(button) {
    try {
        const config = button.getAttribute('data-paypal-button-config');

        if (config) {
            const buttonConfigs = JSON.parse(config);

            return buttonConfigs.style;
        }

        return {
            style: defaultStyle
        };
    } catch (error) {
        return {
            style: defaultStyle
        };
    }
}

/**
 * Creates a redirecting form to Order-Confirm endpoint
 * @param {Object} param  The helping object for creating a from
 * @returns {Object} form element
 */
function createConfirmForm(param) {
    const form = $('<form>')
        .appendTo(document.body)
        .attr({
            method: 'POST',
            action: param.url
        });

    $('<input>')
        .appendTo(form)
        .attr({
            name: 'orderID',
            value: param.orderID
        });

    $('<input>')
        .appendTo(form)
        .attr({
            name: 'orderToken',
            value: param.orderToken
        });

    return form;
}

/**
 * Prepare and submits form in order to confirm order with Lpm
 * @param {string} redirectUrl Redirect Url
 */
function processPayNowConfirmForm(redirectUrl) {
    const splitUrl = redirectUrl.split('?');
    const url = splitUrl[0];
    const paramsString = splitUrl[1];
    const searchParams = new URLSearchParams(paramsString);
    const formParam = {
        orderID: searchParams.get('orderID'),
        orderToken: searchParams.get('orderToken'),
        url: url
    };

    const form = createConfirmForm(formParam);

    form.submit();
}

/**
 * Return payment method name in lovercase
 * @param {string} paymentMethodName Payment method name
 * @returns {string} Paymnet method name
 */
function getPaymentMethodToLowerCase(paymentMethodName) {
    const paymentMethod = paymentMethodName.split('_');

    if (paymentMethod.length === 1) {
        return paymentMethodName;
    }

    paymentMethod.forEach(function(element, index) {
        paymentMethod[index] = element.charAt(0) + element.slice(1).toLocaleLowerCase();
    });

    return paymentMethod.length ? paymentMethod.join(' ') : paymentMethod[0];
}

/**
 * Creates an HTML element with the specified tag and text.
 * @param {string} tag - The tag name for the new element.
 * @param {string} text - The text content for the new element.
 * @returns {HTMLElement} A new HTML element with the specified tag and text.
 */
function createHTMLElementWithText(tag, text) {
    const element = document.createElement(tag);

    element.textContent = text;

    return element;
}

/**
 * Updates html div view
 * @param {Object} order Order object
 * @param {Array<HTMLElement>} html Array of HTML elements
 * @returns {Array<HTMLElement>} Updated array of HTML elements
 */
function appendHtml(order, html) {
    const payment = order.billing.payment;

    payment.selectedPaymentInstruments.forEach(function(selectedPaymentInstrument) {
        const paymentMethodId = selectedPaymentInstrument.paymentMethod;
        const fundingSource = selectedPaymentInstrument.fundingSource;
        const isVenmoUsed = fundingSource === window.paypalConstants.PAYMENT_METHOD_ID_VENMO
            && document.getElementById('venmo-content').classList.contains('active');

        const availablePaymentMethods = [
            window.paypalConstants.PAYMENT_METHOD_ID_PAYPAL,
            window.paypalConstants.PAYMENT_METHOD_ID_VENMO
        ];

        if (fundingSource === window.paypalConstants.PP_DEBIT_CREDIT_PAYMENT_TYPE) {
            html.push(createHTMLElementWithText('div', window.paypalConstants.PP_DEBIT_CREDIT_PAYMENT_TYPE));
        } else if (isVenmoUsed) {
            html.push(createHTMLElementWithText('div', window.paypalConstants.PAYMENT_METHOD_ID_VENMO));
        } else {
            html.push(createHTMLElementWithText('div', getPaymentMethodToLowerCase(paymentMethodId)));
        }

        if (paymentMethodId !== window.paypalConstants.PAYMENT_METHOD_ID_PAYPAL
            && selectedPaymentInstrument.maskedCreditCardNumber) {
            html.push(createHTMLElementWithText('div', selectedPaymentInstrument.maskedCreditCardNumber));
        }

        if (availablePaymentMethods.includes(paymentMethodId)) {
            const text = [selectedPaymentInstrument.accountHolderResource, selectedPaymentInstrument.paypalAccountHolder].join('');

            html.push(createHTMLElementWithText('div', text));
        }

        if (selectedPaymentInstrument.type) {
            html.push(createHTMLElementWithText('div', selectedPaymentInstrument.type));
        }

        html.push(createHTMLElementWithText('div', '$' + selectedPaymentInstrument.amount));
    });

    return html;
}

/**
 * Updates checkout view
 * @param {Object} data Data object
 */
function updateCheckoutView(data) {
    const $paymentSummary = document.querySelector('.summary-details .payment-details')
        || document.querySelector('.summary-details .js-paypal-payment-details');

    const order = data.order;
    const payment = order.billing.payment;

    let htmlToAppend = [];

    if ($paymentSummary && $paymentSummary.classList.contains('payment-details')) {
        // Overwrites a basic class to show a correct payment PayPal related data for buyer
        $paymentSummary.classList.remove('payment-details');
        $paymentSummary.classList.add('js-paypal-payment-details');
        $paymentSummary.classList.add('js-affirm-payment-description');
    }

    if (payment && payment.selectedPaymentInstruments && payment.selectedPaymentInstruments.length > 0) {
        htmlToAppend = appendHtml(order, htmlToAppend);
    }

    if ($paymentSummary && htmlToAppend.length > 0) {
        $paymentSummary.innerHTML = '';

        htmlToAppend.forEach(element => {
            $paymentSummary.appendChild(element);
        });
    }
}

/**
 * @param {Object} error - an error object data
 * @returns {boolean} - if an error message is displayed then true otherwise false
 */
function handleValidationAddressResult(error) {
    if (error.status === 422 && /(sh|b)i(p|l){2}ing/i.test(error.responseText)) {
        const AlertHandlerModel = require('../models/alertHandler');
        const alertHandler = new AlertHandlerModel();

        alertHandler.showError(error.responseText);

        return true;
    }

    return false;
}

/**
 * Shows paypal button
 * @param {Object} paypalButton - paypal button element
 */
function showPaypalButton(paypalButton) {
    paypalButton.classList.remove(HIDE_CLASS);
}

/**
 * Hides paypal button
 * @param {Object} paypalButton - paypal button element
 */
function hidePaypalButton(paypalButton) {
    paypalButton.classList.add(HIDE_CLASS);
}

/**
 * Check if product price value is equal to zero (0.00)
 * @returns {boolean} - a true for zero value or false
 */
function isProductZeroAmount() {
    const $element = document.querySelector(PDP_PRICE_VALUE_CLASS);

    return $element && $element.getAttribute('content') === '0.00';
}

/**
 * Get minicart qualtity
 * @returns {number} - a quantity
 */
function getMiniCartQuantity() {
    const $element = document.querySelector('.minicart-quantity');

    return $element ? parseInt($element.textContent, 0) : 0;
}

/**
 * Handling PVP button behavior on Quick View
 * @param {Object} paypalButton - paypal button element
 * @returns {void}
 */
function initPaypalButtonBehaviorOnPvp(paypalButton) {
    const isProductReadyToOrderElement = document.querySelector('.js-is-show-pp-button');
    const isProductReadyToOrder = isProductReadyToOrderElement && isProductReadyToOrderElement.value === 'true';

    const addToCartButton = document.querySelector('.add-to-cart, .add-to-cart-global');

    // Check minicart quantity and hide Paypal button if it is not empty
    if (getMiniCartQuantity() > 0 || isProductZeroAmount()) { // Check if product price is zero
        hidePaypalButton(paypalButton);
    }

    if (addToCartButton.disabled || !isProductReadyToOrder) {
        hidePaypalButton(paypalButton);
    }

    $('body').on('product:statusUpdate', function() {
        if (getMiniCartQuantity() === 0 && !isProductZeroAmount()) {
            if (addToCartButton.disabled) {
                hidePaypalButton(paypalButton);
            }

            if (!addToCartButton.disabled) {
                showPaypalButton(paypalButton);
            }
        } else {
            hidePaypalButton(paypalButton);
        }
    });
}

/**
 * Handling PVP/PDP button behavior on Product Set
 * @returns {void}
 */
function initPayPalBtnBehaviorOnSet() {
    const miniCartQuantityElement = document.querySelector('.minicart-quantity');
    const addToCartButtons = document.querySelectorAll('.add-to-cart, .add-to-cart-global');

    const miniCartQuantity = parseInt(miniCartQuantityElement.textContent, 0);

    addToCartButtons.forEach((btn) => {
        const paypalButton = btn.parentElement
            .querySelector('.paypal-pvp-button, .paypal-pvp-button-global, .paypal-pdp-button, .paypal-pdp-button-global');

        const isProductReadyToOrderElement = btn.parentElement.querySelector('.js-is-show-pp-button');
        const isProductReadyToOrder = isProductReadyToOrderElement && isProductReadyToOrderElement.value === 'true';

        const prices = document.querySelectorAll(PDP_PRICE_VALUE_CLASS);

        const isWrongAmount = prices && Array.from(prices).some((price) => {
            return parseFloat(price.getAttribute('content')) <= 0;
        });

        if (miniCartQuantity === 0 && !isWrongAmount && isProductReadyToOrder) {
            if (btn.disabled) {
                hidePaypalButton(paypalButton);
            }

            if (!btn.disabled) {
                showPaypalButton(paypalButton);
            }
        } else {
            hidePaypalButton(paypalButton);
        }
    });

    $('body').on('product:statusUpdate', function(event) {
        const addToCartButton = event.target;

        const paypalButton = addToCartButton.parentElement
            .querySelector('.paypal-pvp-button, .paypal-pvp-button-global, .paypal-pdp-button, .paypal-pdp-button-global');

        const prices = document.querySelectorAll(PDP_PRICE_VALUE_CLASS);

        const isWrongAmount = prices && Array.from(prices).some((price) => {
            return parseFloat(price.getAttribute('content')) <= 0;
        });

        if (miniCartQuantity === 0 && !isWrongAmount) {
            if (addToCartButton.disabled) {
                hidePaypalButton(paypalButton);
            }

            if (!addToCartButton.disabled) {
                showPaypalButton(paypalButton);
            }
        } else {
            hidePaypalButton(paypalButton);
        }
    });
}

/**
 * Returns a payment method name by the Paypal funding source
 * @param {string} fundingSource PayPal funding source
 * @returns {string} A payment method name
 */
function getPaymentMethodName(fundingSource) {
    if (fundingSource === 'venmo') {
        return window.paypalConstants.PAYMENT_METHOD_ID_VENMO;
    }

    if (fundingSource === 'card') {
        return window.paypalConstants.PP_DEBIT_CREDIT_PAYMENT_TYPE;
    }

    return fundingSource;
}

/**
 * Get CSRF Token
 * @returns {string} - csrf token value
 */
function getCsrfToken() {
    let $element = document.querySelector('[name="csrf_token"]');

    if ($element && $element.value !== '') {
        return $element.value;
    }

    $element = document.querySelector('[data-tokenname="csrf_token"]');

    if ($element && $element.getAttribute('data-token') !== '') {
        return $element.getAttribute('data-token');
    }

    return '';
}

/**
 * Add csrf token param to url
 * @param {string} url - source url
 * @returns {string} - url with csrf_token param
 */
function getUrlWithCsrfToken(url) {
    const urlInstance = new URL(url, location.origin);

    urlInstance.searchParams.append('csrf_token', getCsrfToken());

    return urlInstance.toString();
}

/**
 * It takes a selector for a PayPal button, and returns an object with the product ID and quantity of
 * the product that the button is for.
 *
 * @param {string} ppBtnSelector - PayPal button container selector.
 * @returns {void}
 */
function addProductToCart(ppBtnSelector) {
    /**
     * Gets options
     * @param {Object} productContainer - product container
     * @returns {string} options
     */
    function getOptions(productContainer) {
        return JSON.stringify(Array.from(productContainer.querySelectorAll('.product-option'))
            .map(elOption => {
                const selectedValue = elOption.querySelector('.options-select').value;
                const selectedOption = elOption.querySelector(`.options-select option[value="${selectedValue}"]`);

                return {
                    optionId: elOption.getAttribute('data-option-id'),
                    selectedValueId: selectedOption.getAttribute('data-value-id')
                };
            }));
    }

    const ppBtn = document.querySelector(ppBtnSelector);
    const bundleItems = document.querySelectorAll('.bundle-item');
    const setItems = document.querySelectorAll('.set-item');

    const ppBtnContainer = ppBtn.closest('.paypal-pvp-button-global, .paypal-pdp-button-global')
        || ppBtn.closest('.paypal-pdp-button, .paypal-pvp-button');

    const isGlobalButton = ['paypal-pvp-button-global', 'paypal-pdp-button-global'].some(className => ppBtnContainer.classList.contains(className));

    const pid = base.getPidValue(ppBtnContainer);

    let productContainer;
    let form = {};

    if (isGlobalButton || !setItems.length) {
        productContainer = document.querySelector(ppBtnSelector).closest(PRODUCT_DETAIL_CLASS) || document.querySelector(PRODUCT_DETAIL_CLASS);
    } else {
        productContainer = document.querySelector(ppBtnSelector).closest('.set-item');
    }

    if (bundleItems.length) {
        const quantity = document.querySelector('.modal-footer').querySelector('.quantity-select').value;

        form = {
            pid: pid,
            quantity: quantity
        };

        const items = Array.from(bundleItems).map(function(item) {
            return {
                pid: item.querySelector('.product-id').innerText,
                quantity: parseInt(item.querySelector('.quantity').getAttribute('data-quantity'), 10)
            };
        });

        form.childProducts = JSON.stringify(items);
    } else if (setItems.length && isGlobalButton) {
        const items = Array.from(setItems).map(function(item) {
            return {
                pid: base.getPidValue(item),
                qty: parseInt(base.getQuantitySelected(item), 10),
                options: getOptions(item)
            };
        });

        form.pidsObj = JSON.stringify(items);
    } else {
        form = {
            pid: pid,
            quantity: base.getQuantitySelected(ppBtnContainer),
            options: getOptions(productContainer)
        };
    }

    return $.ajax({
        url: $('.add-to-cart-url').val(),
        method: 'POST',
        async: false,
        data: form
    }).responseJSON;
}

/**
 * Removes all products from the cart in case if an error occurred
 */
function removeAllProductsFromCart() {
    const url = getUrlWithCsrfToken(window.paypalUrls.removeAllProductsFromCart);

    fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .finally(() => {
            $.spinner().stop();
        });
}

/**
 * The function calls ReturnFromCart route and redirects the user to the place order stage.
 * @param {Object} - object from onApprove function.
 * @param {boolean} isAddressNeedChange whether address should be updated or not in case of static image button
 * @returns {Promise} object that resolves to undefined.
 */
function returnFromCart({ id, payer }, isAddressNeedChange = false) {
    const api = require('./api');

    const that = this;
    let url = getUrlWithCsrfToken(window.paypalUrls.returnFromCart);
    let cartBillingFormData;

    if (that.usedPaymentMethodName) {
        cartBillingFormData = api.createCartBillingFormData({
            usedPaymentMethod: that.usedPaymentMethodName
        }, that.$payPalBtnContainer);
    } else if (id && payer) {
        cartBillingFormData = api.createCartBillingFormData({
            billingAgreementID: id,
            billingAgreementPayerEmail: payer.payer_info.email
        }, that.payPalButton);
    } else {
        url = `${url}&isAddressNeedChange=${isAddressNeedChange}`;
        cartBillingFormData = api.createCartBillingFormData();
    }

    return fetch(url, {
        method: 'POST',
        body: cartBillingFormData
    })
        .then((response) => response.json())
        .then((data) => {
            if (this.loader) {
                this.loader.hide();
            }

            data.error
                ? window.location.href = data.redirectURL
                : window.location.href = window.paypalUrls.placeOrderStage;
        });
}

/**
 * function to proceed a save parsing
 * @param {string} element string what should be parsed
 * @returns {Object} result of parsing
 */
function tryParseJSON(element) {
    const AlertHandlerModel = require('../models/alertHandler');
    const alertHandler = new AlertHandlerModel();

    let result = null;

    try {
        result = JSON.parse(element);
    } catch (error) {
        alertHandler.showError(window.paypalConstants.FLASH_MESSAGE_ERROR_INTERNAL_SERVER);
    }

    return result;
}

/**
 * @param {mixed} value object key
 * @param {mixed} defaultValue default value
 * @returns {mixed} return current or default value
 */
function getValueOr(value, defaultValue) {
    return value || defaultValue;
}

/**
 * The function updates the data attributes of a DOM element with the values from a billing address object.
 * @param {Object} billingAddress - an object that contains information about a billing address.
 * @param {string} optionClassName - a class name of option to update the attributes.
 * @returns {void}
 */
function updateOptionDataAttribute(billingAddress, optionClassName) {
    const $option = document.querySelector(`.${optionClassName}`);

    Object.entries(billingAddress).forEach(([key, val]) => {
        if (key === 'countryCode') {
            $option.dataset[key] = val.value;
        } else {
            $option.dataset[key] = val;
        }
    });
}

/**
 * The function takes a billing address object and returns a string representation of the address.
 * @param {Object} billingAddress - an object that contains information about a billing address.
 * @returns {string} - a billing address converted to a string in specific format.
 */
function getBillingAddressAsString(billingAddress) {
    let billingAddressStringRepresentation = '';

    if (Object.keys(billingAddress).length) {
        const firstName = getValueOr(billingAddress.firstName, '');
        const lastname = getValueOr(billingAddress.lastName, '');
        const address1 = getValueOr(billingAddress.address1, '');
        const city = getValueOr(billingAddress.city, '');
        const state = getValueOr(billingAddress.stateCode, '');
        const postalCode = getValueOr(decodeURIComponent(billingAddress.postalCode), '');

        billingAddressStringRepresentation = `${firstName} ${lastname} ${address1} ${city} ${state} ${postalCode}`;
    }

    return billingAddressStringRepresentation;
}

/**
 * The function is used to display a selected stored billing address in a dropdown menu.
 * @param {Object} billingAddress - an object that contains information about a billing address.
 * @param {string} optionClassName - a class name of the option that will display the selected billing address.
 * @returns {void}
 */
function displaySelectedStoredBillingAddress(billingAddress, optionClassName) {
    const billingAddressAsString = getBillingAddressAsString(billingAddress);

    if (billingAddressAsString !== '') {
        const $storedCreditCardAddressOption = document.querySelector(`.${optionClassName}`);

        if ($storedCreditCardAddressOption) {
            $storedCreditCardAddressOption.innerText = billingAddressAsString;
            $storedCreditCardAddressOption.selected = true;
            $storedCreditCardAddressOption.hidden = false;
        } else {
            const option = document.createElement('option');

            option.innerHTML = billingAddressAsString;
            option.selected = true;
            option.classList.add(optionClassName);

            document.getElementById('billingAddressSelector').append(option);
        }
    }
}

/**
 * The function updates the billing address form and displays the stored billing address.
 * @param {Element} $selectedOption - the selected option from a saved credit cards list.
 * @returns {void}
 */
function selectBillingAddress($selectedOption) {
    const baseBilling = require('base/checkout/billing');

    const storedCcAddressClassName = 'js-stored-credit-card-address';
    const billingAddress = tryParseJSON($selectedOption.dataset.billingAddress);
    const order = {
        billing: {
            billingAddress: {
                address: billingAddress
            }
        }
    };

    baseBilling.methods.updateBillingAddress(order);
    displaySelectedStoredBillingAddress(billingAddress, storedCcAddressClassName);
    updateOptionDataAttribute(billingAddress, storedCcAddressClassName);
}

/**
 * Validate whole form.
 * @param {HTMLElement} form - form to be validated.
 * @returns {Object} - with flag to indicate if form is valid and with an array of invalid fields
 */
function validateForm(form) {
    if (form.checkValidity && !form.checkValidity()) {
        const invalidFields = Array.from(form.querySelectorAll('input, select')).reduce((accum, element) => {
            if (!element.validity.valid) {
                element.classList.add(INVALID_CLASSNAME);
                accum.push(element);
            }

            return accum;
        }, []);

        return {
            isValid: false,
            invalidFields: invalidFields
        };
    }

    return {
        isValid: true
    };
}

export {
    getPaypalButtonStyle,
    processPayNowConfirmForm,
    updateCheckoutView,
    handleValidationAddressResult,
    initPaypalButtonBehaviorOnPvp,
    initPayPalBtnBehaviorOnSet,
    getPaymentMethodName,
    getCsrfToken,
    getUrlWithCsrfToken,
    addProductToCart,
    removeAllProductsFromCart,
    returnFromCart,
    tryParseJSON,
    selectBillingAddress,
    validateForm
};
