const loaderInstance = require('../components/loader');
const helper = require('./helper');
const $loaderContainer = document.querySelector('.paypalLoader');
const loader = loaderInstance($loaderContainer);
const CONTENT_TYPE_JSON = 'application/json';

const AlertHandlerModel = require('../models/alertHandler');
const alertHandler = new AlertHandlerModel();

/**
 * Updates information about an order
 * @param {boolean} isAddressNeedChange - if we need to change shipping address
 * @returns {Object} Call handling result
 */
function updateOrderData(isAddressNeedChange = false) {
    loader.show();

    return $.ajax({
        url: helper.getUrlWithCsrfToken([window.paypalUrls.updateOrderData,
            '?isCartFlow=true', '&isAddressNeedChange=', isAddressNeedChange].join('')),
        type: 'PATCH',
        success: () => {
            loader.hide();
            window.location.href = window.paypalUrls.placeOrderStage;
        },
        error: (err) => {
            const error = JSON.parse(err.responseText);

            loader.hide();
            alertHandler.showError(error.errorMsg);

            if (error.transactionExpired) {
                location.reload();
            }
        }
    });
}

/**
 * Gets Billing Agreement Token
 *
 * @param {boolean} isCartFlow - billing agreement flow from cart
 * @param {boolean} isSkipShippingAddress - whether skip or add possibility to edit the shipping address in PayPal window
 * @returns {string} billingToken - returns a JSON response that includes token, an approval URL
 */
function getBillingAgreementToken(isCartFlow, isSkipShippingAddress = false) {
    const url = helper.getUrlWithCsrfToken([window.paypalUrls.createBillingAgreementToken,
        '?isCartFlow=', isCartFlow, '&isSkipShippingAddress=', isSkipShippingAddress].join(''));

    return $.get(url)
        .then((data) => data);
}

/**
 * Gets Billing Agreement
 * After buyer approval, you use a billing agreement token to create the agreement.
 * @returns {Object} JSON response body that includes the billing agreement ID,
 * the state of the agreement, which is ACTIVE, and information about the payer
 */
function createBillingAgreementCall() {
    return $.ajax({
        url: helper.getUrlWithCsrfToken(window.paypalUrls.createBillingAgreement),
        type: 'POST',
        contentType: CONTENT_TYPE_JSON
    });
}

/**
 * Create billing formData from fields data
 *
 * @param {Object} fieldsData - fields data values
 * @param {Element} $paypalButton - paypal button selector
 * @returns {Object} cart billing form data
 */
function createCartBillingFormData(fieldsData, $paypalButton) {
    const cartBillingFormData = new FormData();

    if (!$paypalButton) {
        $paypalButton = document.getElementById('paypal-static-image')
            || document.getElementById('paypal-image')
            || document.getElementById('venmo-image');
    }

    const cartBillingFields = $paypalButton && JSON.parse($paypalButton.getAttribute('data-paypal-billing-form-fields'));

    Object.entries(cartBillingFields)
        .forEach(entry => {
            const [key, field] = entry;

            if (typeof field === 'object') {
                cartBillingFormData.append(field.name, fieldsData && fieldsData[key] ? fieldsData[key] : field.value);
            }
        });

    return cartBillingFormData;
}

/**
 * Calls to returnFromCart endpoint for Static image button, redirects to place order stage or shows error if it exists
 * @param {boolean} isAddressNeedChange - true if static image address same as BA
 * @returns {Object} Call handling result
 */
function returnFromCart(isAddressNeedChange = false) {
    loader.show();

    return helper.returnFromCart(isAddressNeedChange)
        .catch((error) => {
            loader.hide();
            alertHandler.showError(error.responseText);
        });
}

/**
 * Calls to Paypal-ValidateStaticImage endpoint
 * @returns {Promise} promise from fetch with response from Paypal-ValidateStaticImage endpoint
 */
function validateStaticImageAddress() {
    const paypalStaticImage = document.getElementById('paypal-static-image')
        || document.getElementById('paypal-image');

    return fetch(helper.getUrlWithCsrfToken(window.paypalUrls.validateStaticImage), {
        method: 'POST',
        headers: {
            'Content-Type': CONTENT_TYPE_JSON
        },
        body: JSON.stringify({
            email: paypalStaticImage.getAttribute('data-paypal-default-ba-email'),
            formFields: paypalStaticImage.getAttribute('data-paypal-billing-form-fields')
        })
    })
        .then(response => response.json())
        .then(data => {
            return data.isAddressNeedChange;
        })
        .catch(() => {
            return false;
        });
}

/**
 * Call finishPayNow endpoint
 * @param {string} fundingSource The current paypal funding source
 * @returns {Promise} ajax call
 */
function finishPayNowFlow(fundingSource) {
    const $usedPaymentMethod = document.querySelector('#usedPaymentMethod');
    const pmName = $usedPaymentMethod ? $usedPaymentMethod.value : fundingSource;

    return $.ajax({
        url: helper.getUrlWithCsrfToken(window.paypalUrls.finishPayNowFlow),
        type: 'POST',
        contentType: CONTENT_TYPE_JSON,
        data: JSON.stringify({
            pmName
        })
    });
}

/**
 * Makes call on saving a PayPal default address to the storefront customer.
 * @param {Object} addressObject PayPal address information.
 * @param {boolean} isAccountPage Identifies whether the source page is account.
 * @returns {Object} Whether a success or failed JSON response body.
 */
function savePaypalDefaultAddress(addressObject, isAccountPage) {
    return $.ajax({
        url: window.paypalUrls.savePaypalDefaultAddress,
        type: 'POST',
        contentType: CONTENT_TYPE_JSON,
        data: JSON.stringify({ addressObject, isAccountPage })
    });
}

/**
 * Finish a PayPal payment by PayPal Pay Now flow
 * @param {string} usedPaymentMethod Current payment method name
 */
function finishPayNowPayment(usedPaymentMethod) {
    finishPayNowFlow(usedPaymentMethod)
        .then(({ redirectUrl }) => {
            loader.hide();
            helper.processPayNowConfirmForm(redirectUrl);
        })
        .catch(function() {
            loader.hide();
        });
}

/**
 * Creates and sets a payPal order id
 * @param {Object} paymentSourceData The payment source data (card, apple_pay...))
 * @returns {string} paypal order id
 */
function getPaypalOrderId(paymentSourceData) {
    let url = helper.getUrlWithCsrfToken(window.paypalUrls.getPaypalOrderId);

    url = paymentSourceData ? [url, '&paymentSourceData=', JSON.stringify(paymentSourceData)].join('') : url;

    return $.ajax({
        url: url,
        type: 'GET',
        contentType: CONTENT_TYPE_JSON,
        async: false
    }).responseJSON.id;
}

/**
 * Validates the hosted fields
 * @param {Object} fieldsState contains fields and validation from PP side
 * @returns {Promise} call from server with validation result
 */
function validateHostedFields(fieldsState) {
    return fetch(helper.getUrlWithCsrfToken(window.paypalUrls.validateHostedFields), {
        method: 'POST',
        body: JSON.stringify(fieldsState),
        headers: { 'Content-Type': 'application/json' }
    });
}

export {
    updateOrderData,
    getBillingAgreementToken,
    createBillingAgreementCall,
    returnFromCart,
    finishPayNowFlow,
    createCartBillingFormData,
    savePaypalDefaultAddress,
    finishPayNowPayment,
    getPaypalOrderId,
    validateStaticImageAddress,
    validateHostedFields
};
