'use strict';

const api = require('../../helpers/api');
const helper = require('../../helpers/helper');
const loaderInstance = require('../../components/loader');

const $loaderContainer = document.querySelector('.paypalLoader');

class PayPalBaseBAModel {
    constructor(selector) {
        const AlertHandlerModel = require('../alertHandler');

        this.selector = selector;
        this.isCartFlow = false;
        this.payPalInstance = null;
        this.loader = loaderInstance($loaderContainer);
        this.alertHandler = new AlertHandlerModel();
        this.payPalButton = document.querySelector(this.selector);
    }

    /**
     * Create's Billing Agreement
     * @returns {string} returns BA token
     */
    createBillingAgreement() {
        const that = this;

        that.loader.show();

        return api.getBillingAgreementToken(that.isCartFlow)
            .then(data => data.token)
            .fail(err => {
                that.payPalInstance.close();

                that.alertHandler.showError(err.responseText);
            });
    }

    /**
     * Makes call to create a BA token
     * @returns {Object} JSON response body that includes the billing agreement ID,
     * the state of the agreement, which is ACTIVE, and information about the payer
     */
    onApprove() {
        return api.createBillingAgreementCall();
    }

    /**
     * Hides loader on paypal widget closing without errors
     */
    onCancel() {
        this.loader.hide();
    }

    /**
     * Shows errors if paypal widget was closed with errors
     */
    onError() {
        this.loader.hide();
    }

    /**
     * Hides loader on paypal widget and reinitiates the PP button
     */
    onClose() {
        this.loader.hide();
        this.initPayPalButton();
    }

    /**
     * Inits paypal Billing Agreement button
     */
    initPayPalButton() {
        const that = this;

        that.loader.show();

        that.payPalInstance = window.paypal.Buttons({
            createBillingAgreement: that.createBillingAgreement.bind(that),
            onApprove: that.onApprove.bind(that),
            onCancel: that.onCancel.bind(that),
            onError: that.onError.bind(that),
            onClose: that.onClose.bind(that),
            style: helper.getPaypalButtonStyle(that.payPalButton)
        });
    }
}

module.exports = PayPalBaseBAModel;
